import { type RemovableRef, useStorage } from '@vueuse/core';
import type { Ref } from 'vue';
import { createCart } from '~/shopify/createCart';
import type { Cart, CartLineInput, CartLineUpdateInput } from '~/types/storefront.types';
import { addCartLines } from '~/shopify/addCartLines';
import { queryCart } from '~/shopify/queryCart';
import { removeCartLines } from '~/shopify/removeCartLines';
import { updateCartLines } from '~/shopify/updateCartLines';

export type CartState = {
  state: RemovableRef<{ cartId: string | null; checkoutUrl: string | null }>;
  cart: Ref<Cart | null>;
  isCartOpen: Ref<boolean>;
  isLoading: Ref<boolean>;
  hasItemsInCart: Ref<boolean>;
  addItemToCart: (item: CartLineInput, openCart?: boolean) => void;
  removeLineFromCart: (lineId: string) => void;
  updateCartLine: (line: CartLineUpdateInput) => void;
  toggleCart: (value?: boolean | undefined) => boolean;
};

export function useCartState(): CartState {
  const state = useStorage<{
    cartId: null | string;
    checkoutUrl: null | string;
  }>('cart', { cartId: null, checkoutUrl: null });

  const isCartOpen = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const cart = ref<null | Cart>(null);

  const toggleCart = useToggle(isCartOpen);

  const hasItemsInCart = computed(function () {
    if (cart.value?.lines) {
      return cart.value.lines.edges.length > 0;
    }

    return false;
  });

  updateCart();

  return {
    state,
    cart,
    isCartOpen,
    isLoading,
    toggleCart,
    hasItemsInCart,
    addItemToCart,
    removeLineFromCart,
    updateCartLine,
  };

  async function updateCart() {
    if (state.value.cartId) {
      const res = await queryCart(state.value.cartId);

      cart.value = res.data?.cart ?? null;

      if (!res.data?.cart) {
        state.value.cartId = null;
        state.value.checkoutUrl = null;
      }
    }
  }

  async function addItemToCart(item: CartLineInput, openCart?: boolean) {
    isLoading.value = true;
    const lines: [CartLineInput] = [item];

    if (!state.value.cartId) {
      const res = await createCart({
        lines,
      });

      if (res.data?.cartCreate?.cart?.id && res.data?.cartCreate?.cart?.checkoutUrl) {
        addCart(res.data.cartCreate.cart.id, res.data.cartCreate.cart.checkoutUrl);
      }
    } else {
      await addCartLines(state.value.cartId, lines);
    }

    if (openCart) {
      toggleCart(true);
    }

    return finishCartExecution();
  }

  async function removeLineFromCart(lineId: string) {
    if (!state.value.cartId) {
      return;
    }

    isLoading.value = true;

    await removeCartLines(state.value.cartId, [lineId]);

    return finishCartExecution();
  }

  async function updateCartLine(line: CartLineUpdateInput) {
    if (!state.value.cartId) {
      return;
    }

    isLoading.value = true;

    await updateCartLines(state.value.cartId, [line]);

    return finishCartExecution();
  }

  async function finishCartExecution() {
    await updateCart();
    isLoading.value = false;
  }

  function addCart(newCartId: string, newCheckoutUrl: string) {
    state.value.cartId = newCartId;
    state.value.checkoutUrl = newCheckoutUrl;
  }
}
