<template>
  <div class="app">
    <LoadingAnimation />
    <NuxtPage />
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
import LoadingAnimation from '~/components/LoadingAnimation.vue';
import { useCartState } from '~/shopify/useCartState';

const nuxtApp = useNuxtApp();

nuxtApp.provide('CartState', useCartState());

onMounted(function () {
  window.scrollTo({
    top: 0,
    behavior: 'instant',
  });
});

useHead({
  title: 'Roots Store',
  htmlAttrs: {
    lang: 'en-CH',
  },
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    { rel: 'manifest', href: '/site.webmanifest' },
  ],
  meta: [
    { name: 'theme-color', content: '#000000' },
    {
      name: 'description',
      content:
        "Since 2015, Roots has been on a mission to inspire plant-powered living. We've developed Roots Protein, a clean, natural, plant-based protein designed for everyone aiming for better health.",
    },
  ],
});


</script>

<style lang="scss">
.app {
  @include sm {
    --inner-width: 100%;
  }

  @include md-lg {
    --inner-width: min(38rem, 80vw);
  }
}

html:not([data-loading-state='finished']) {
  .dialog-element {
    display: none !important;

  }
}


</style>
